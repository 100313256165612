import React from 'react'
import { v4 } from 'uuid'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
// import PublishedArticles from "../components/PublishedArticles/PublishedArticles"

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { MAX_WIDTH } from '../utils/MediaQueries'
import {
  ANIMATION_DELAY,
  ColorScheme,
  POST_OVERLAY,
  SEO_KEYWORDS,
  MOBILE_POST_HEIGHT,
  BOX_SHADOW,
} from '../utils/Variables'

const OverflowedDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: all 0.5s ease-in-out;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  box-shadow: ${BOX_SHADOW};

  &:hover {
    transform: scale(1.02);
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }

  @media (max-width: ${MAX_WIDTH}px) {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }
`

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 10px;

  @media (max-width: ${MAX_WIDTH}px) {
    grid-template-columns: 1fr;
  }
`

const Post = styled(OverflowedDiv)`
  height: 340px;

  @media (max-width: ${MAX_WIDTH}px) {
    height: ${MOBILE_POST_HEIGHT};
  }
`

const VerticalPost = styled(OverflowedDiv)`
  height: 700px;
  grid-row: span 2;

  @media (max-width: ${MAX_WIDTH}px) {
    grid-row: auto;
    height: ${MOBILE_POST_HEIGHT};
  }
`

const LeaderboardPost = styled(OverflowedDiv)`
  grid-column: span 2;
  height: 500px;

  @media (max-width: ${MAX_WIDTH}px) {
    grid-column: auto;
    height: ${MOBILE_POST_HEIGHT};
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-color: ${POST_OVERLAY};
    opacity: 0.8;
  }
`

const MetaData = styled.div`
  position: absolute;
  bottom: 30px;
  left: 20px;
`

const PostDate = styled.span`
  bottom: 170px;
  color: ${ColorScheme.offWhite};
  font-size: 0.9rem;
  user-select: none;

  @media (max-width: ${MAX_WIDTH}px) {
    font-size: 0.8rem;
  }
`

const PostTitle = styled.h1`
  font-size: 1.7rem;
  font-weight: 500;
  width: 70%;
  color: ${ColorScheme.offWhite};
  line-height: 1.1;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  padding: 0;
  margin: 0;

  @media (max-width: ${MAX_WIDTH}px) {
    font-size: 1.4rem;
  }
`

const ReadMore = styled.span`
  color: ${ColorScheme.offWhite};
  border-bottom: 2px solid;
  margin-top: 10px;
  white-space: nowrap;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  letter-spacing: -0.5px;
  transition: ${ANIMATION_DELAY};

  &:hover {
    border-bottom: 2px solid transparent;
  }

  @media (max-width: ${MAX_WIDTH}px) {
    font-size: 0.8rem;
  }
`

const renderMoreOrWatch = postType => (
  <ReadMore>{postType === 'article' ? 'Read More' : 'Watch'}</ReadMore>
)
const renderMarkUp = ({
  fields: { slug },
  frontmatter: { photo, title, date, post_type },
}) => (
  <Link to={slug}>
    <Overlay bgImage={photo} />
    <MetaData>
      <PostDate>{date}</PostDate>
      <PostTitle>{title}</PostTitle>
      {renderMoreOrWatch(post_type)}
    </MetaData>
  </Link>
)

const renderPost = (post, index) => (
  <Post key={index}>{renderMarkUp(post)}</Post>
)

const renderVerticalPost = (post, index) => (
  <VerticalPost key={index}>{renderMarkUp(post)}</VerticalPost>
)

const renderLeaderboardPost = (post, index) => (
  <LeaderboardPost key={index}>{renderMarkUp(post)}</LeaderboardPost>
)

export default function Home(props) {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
      allMarkdownRemark: { edges: posts },
    },
    location,
  } = props

  return (
    <Layout location={location} title={title}>
      <SEO title="Home" keywords={SEO_KEYWORDS} />
      {/* <PublishedArticles /> */}
      <Wrapper>
        {renderLeaderboardPost(posts[0].node, 0)}
        {renderVerticalPost(posts[1].node, 1)}
        {posts.slice(2).map(({ node }, index) => {
          if (index === 2) return renderLeaderboardPost(node, v4())
          if (index === 3) return renderVerticalPost(node, v4())
          return renderPost(node, v4())
        })}
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            photo
            post_type
          }
        }
      }
    }
  }
`
